<template>
  <div id="app" class="layout-content">
    <ContentDropdownNavigationBar />

    <main>
      <nuxt />
    </main>

    <Footer class="mt-auto">
      <FooterOfficeInformation />
    </Footer>
  </div>
</template>

<script>
import ContentDropdownNavigationBar from 'chimera/all/themes/blueflow/components/layout/ContentDropdownNavigationBar'
import FooterOfficeInformation from 'chimera/all/themes/blueflow/components/layout/FooterOfficeInformation'
import Footer from '~/components/layout/Footer'

export default {
  name: 'ContentLayout',

  components: {
    ContentDropdownNavigationBar,
    Footer,
    FooterOfficeInformation,
  },
}
</script>
