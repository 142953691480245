<template>
  <Footer :columns="columns">
    <slot />
  </Footer>
</template>

<script>
import Footer from 'chimera/all/themes/blueflow/components/layout/Footer'

export default {
  components: {
    Footer,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      columns: [
        {
          title: 'À propos de nous',
          links: [
            {
              text: 'Contact',
              to: '/contact',
            },
          ],
        },
        {
          title: 'Professionnels',
          links: [
            {
              text: 'Inscrivez-vous en tant que Pro',
              to: '/contact-entreprise',
            },
          ],
        },
      ],
    }
  },
}
</script>
