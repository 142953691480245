<template>
  <div class="footer-office-information">
    <div class="footer-office-information__container container">
      <div class="footer-office-information__logo-container">
        <InlineSvg class="footer-office-information__logo" :src="imageSrc" />
      </div>

      <div class="footer-office-information__column-container">
        <div class="footer-office-information__column">
          <h2 class="footer-office-information__title">Bambelo B.V.</h2>
          <p class="footer-office-information__text">Prinsenkade 5B</p>
          <p class="footer-office-information__text">4811 VB, Breda</p>
          <p class="footer-office-information__text">
            <a href="tel:3292230490">(0)92 230 490</a>
          </p>
          <p class="footer-office-information__text">
            <a href="mailto:info@bambelo.com">info@bambelo.com</a>
          </p>
          <p class="footer-office-information__text">KVK: 20111350</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InlineSvg from 'vue-inline-svg'
import usesFeatureFlag from 'chimera/all/mixins/usesFeatureFlag'

export default {
  name: 'FooterOfficeInformation',

  components: {
    InlineSvg,
  },

  mixins: [usesFeatureFlag],

  props: {
    imageSrc: {
      type: String,
      default: 'images/logo-icon.svg',
    },
  },
}
</script>
